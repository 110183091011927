import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Stact from "./Stact";

import TagManager from 'react-gtm-module'
// import ReactGA from "react-ga4";
// ReactGA.initialize("G-5K20NQSVHE");

// ReactGA.send({ hitType: "pageview", page: "/" });
// ReactGA.pageview(window.location.pathname);

const tagManagerArgs = {
  gtmId: 'GTM-PW2R68FG'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Stact />
  </React.StrictMode>
);

// If you want to start measuring performance in your  , pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
