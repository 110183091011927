import Helmet from "react-helmet";
import ContactSection from "../../components/contactSection";
import FeatureCards from "../../components/featureCards";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import "./style.scss";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Lighthouse Software - Services</title>
      </Helmet>
      <NavBar />
      <div className="services-container">
        <Hero />
        <ServicesSection />
        <Showcase />

        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">Services</div>
              <h1 className="display-1">
                We help transform your business from top to bottom.
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ServicesSection = () => {
  const services = [
    {
      name: "Mobile Donation",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico1.svg",
    },
    {
      name: "Online Community",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico2.svg",
    },
    {
      name: "Content Delivery",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico3.svg",
    },
    {
      name: "Prayer Wall",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico4.svg",
    },
    {
      name: "E-learning",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico5.svg",
    },
    {
      name: "ChMs",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico6.svg",
    },
    {
      name: "Website & App Development",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico7.svg",
    },
    {
      name: "Data Insights",
      info: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
      icoUrl: "assets/service_ico8.svg",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row gx-md-5 gy-5">
            {services.map((service, i) => (
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6" key={i}>
                <ServiceCards data={service} src="services" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Showcase = () => {
  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-lg-5">
            <div className="col-lg-6">
              <img
                src={require("./assets/showcase1.png")}
                className="img-fluid"
                alt="feature1"
              />
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Events</div>
              <h2 className="mb-16 display-2">
                Events Calendar and Church Bulletin
              </h2>
              <p className="txt-1">
                Stay connected with our digital bulletin! Browse events from
                Vacation Bible School to weekend services, share, register, and
                more. Engage your community effortlessly and keep them informed.
              </p>
            </div>
          </div>
          <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
            <div className="col-lg-6 order-1 order-lg-0 align-self-center">
              <div className="section-tag mb-8">Content</div>
              <h2 className="mb-16 display-2">
                Sermon, Media, Podcast, and Audio Player{" "}
              </h2>
              <p className="txt-1">
                Amplify your message beyond the sanctuary and make it possible
                for people to connect with your lessons while on the go.
                <br></br>
                <br></br>
                <ul>
                  <li>
                    Provide full sermons, podcasts, and other media archives so
                    people can go back and listen again and again.{" "}
                  </li>
                  <li>
                    Make it easy for members to share a message that moves them
                    via email, text message, or social media
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src={require("./assets/showcase2.png")}
                className="img-fluid"
                alt="feature1"
              />
            </div>
          </div>
        </div>

        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-lg-5">
            <div className="col-lg-6">
              <img
                src={require("./assets/showcase3.png")}
                className="img-fluid"
                alt="feature1"
              />
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Giving</div>
              <h2 className="mb-16 display-2">One-Tap Mobile Giving</h2>
              <p className="txt-1">
                Nobody likes poking at their screens to type long card numbers,
                addresses, and other unnecessary information. Now we can enable
                your members to save their details and give with a single tap.
                <br></br>
                <br></br>
                Once they have an account they can give and tithe through the
                mobile app.
                <br></br>
                <br></br>
                Give with one-tap on your iPhone or Android phone.
                <ul>
                  <li>Set up recurring monthly payments</li>
                  <li>Notify on donation for new events</li>
                  <li>Collect points for credit card</li>
                  <li>Pay for merchandise</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row gy-4 gx-0 gx-lg-5 feature-row-margin">
            <div className="col-lg-6 order-1 order-lg-0 align-self-center">
              <div className="section-tag mb-8">Social Media</div>
              <h2 className="mb-16 display-2">God-centered Social Media </h2>
              <p className="txt-1">

                Let members to post social media updates on your app, allowing
                them to share experiences and engage with all members in the church

                Give members, friends, family, visitors and your community the
                ability to read the bible and submit prayers directly in the
                church app so that your church can encourage one another. 
                <br></br>
                <br></br>
                Drive continuous app engagement by people posting, sharing
                prayers, life updates, church events, throught your own app
                <br></br>
                <br></br>
                Build unity and encouragement by encouraging members to pray for each other
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src={require("./assets/showcase4.png")}
                className="img-fluid"
                alt="feature1"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Services;
