import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import ContactSection from "../../components/contactSection";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import PricingCard from '../../components/pricingCard'

import "./style.scss";
import React from "react";

import ReactPlayer from "react-player/lazy";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import WhatsappPhoto from "./assets/logo.jpeg";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Lighthouse Software </title>
      </Helmet>
      <NavBar />
      <FloatingWhatsApp
        accountName="Lighthouse Software"
        avatar={WhatsappPhoto}
        className="whatsapp-icon"
        darkMode
        phoneNumber="+60122238785"
      />

      <div className="home-showcase-container">
        <Hero />
        <Services />
        <About />
        <About2 />
        <Culture />
        <Counters />
        <Pricing />
        <Donate />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  const brands = [
    {
      name: "brand1",
      imgUrl: "assets/brand1.svg",
    },
    {
      name: "brand2",
      imgUrl: "assets/brand2.svg",
    },
    {
      name: "brand3",
      imgUrl: "assets/brand3.svg",
    },
    {
      name: "brand4",
      imgUrl: "assets/brand4.svg",
    },
    {
      name: "brand5",
      imgUrl: "assets/brand5.svg",
    },
  ];

  return (
    <>
      <section className="section-global no-border">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 align-self-center">
              <h1>Free Digital infrastructure for your church.</h1>
              <p className="txt-1 mt-16 me-lg-5">
                We are a software development agency building products for
                Churches. We digitalise churches with technology and empower
                spiritual communities through innovative apps.
              </p>
              <form className="mt-20 me-lg-5">
                <div className="cta-form d-flex align-items-center justify-content-between">
                  <div className="cta-btn">
                    <a href="mailto:hello@light-hx.com" class="email-button">
                      <button type="button" className="btn btn-primary btn-lg">
                        Get a demo
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url="https://www.youtube.com/watch?v=Gt2i9eSPDX8"
                  width="100%"
                  height="400px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <BrandSection brands={brands} src={'home'} bordered/> */}
    </>
  );
};

const Services = () => {
  const services = [
    {
      name: "Website & App Development",
      info: "Build a stunning website & mobile app that can help your ministry attract, engage and develop your community.",
      link: "/services",
      icoUrl: "assets/service_ico1.svg",
    },
    {
      name: "Church management system",
      info: "ChMS streamlines church operations, fostering community, empowering teams, saving time, nurturing growth, and managing resources for effective stewardship.",
      link: "/services",
      icoUrl: "assets/service_ico2.svg",
    },
    {
      name: "Donation",
      info: "Giving to your church should be meaningful, simple, and fast. That’s why we built a complete set of digital giving tools for your church.",
      link: "/services",
      icoUrl: "assets/service_ico3.svg",
    },
    {
      name: "Community",
      info: "Allow your church goers to be more engaged, run a Christian social media within your Church app!",
      link: "/services",
      icoUrl: "assets/service_ico4.svg",
    },
  ];
  return (
    <>
      <section className="section-global bg-shade-pink">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">What we do</div>
              <h2>A technology-first approach to tithes and community</h2>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <div
                className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"
                key={i}
              >
                <ServiceCards data={service} src="home" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const About = () => {
  const features_data = [
    "Social: Build a Christian social network for your church.",
    "University: Start a Christian bible schools for both adults & kids",
    "Events: Increase your turn up to your various church events",
    "Content: Host all of your church related content, encourage members to create",
    "Push notifications: Notify your community directly to increase your results",
  ];

  const collab_data = [
    "Drive financial growth to missions, while increasing the number social responsibility activities.",
    "Strengthen user loyalty, retention, and brand affinity by nurturing a connected and engaged user base.",
    "Unleash creativity and support a variety of causes, attracting a broader audience with versatile campaigns.",
  ];

  return (
    <>
      <section className="section-global bg-shade-1">
        <div className="container">
          <div className="row mb-40 gy-4">
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about1.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Growth</div>
              <h2 className="mb-16">Collaborative Fundraising Made Easy</h2>
              <p className="txt-1">
                Amplify engagement as your audience becomes active participants,
                driving a sense of unity and shared mission.
                <br />
                <br />
                {collab_data.map((e, i) => (
                  <div
                    key={i}
                    className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <rect width="24" height="24" rx="12" fill="#0FA958" />
                      <path
                        d="M6.66675 12L10.6667 16L17.3334 8"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {e}
                  </div>
                ))}{" "}
              </p>
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-5 gy-4">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Solutions</div>
              <h2 className="mb-16">
                A comprehensive church operations solution that your entire
                ministry will appreciate.
              </h2>
              <p className="txt-1">
                An all-in-one ChMS solution dedicated to (People, App Builder,
                Websites, Streaming, Giving) into one seamless, easy-to-use ChMS
                solution with the mission of equipping your staff, volunteers,
                and congregation with everything they need to foster a healthy
                church.
              </p>
              {features_data.map((e, i) => (
                <div
                  key={i}
                  className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {e}
                </div>
              ))}
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about2.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const About2 = () => {
  const timeline = [
    {
      title: "Drive",
      text: "Continuous app engagement by allowing people to post prayers and pray for one another through prayer wall",
    },
    {
      title: "Encourage",
      text: 'Folks to "open the Bible" right inside your church app so they can follow along during sermons',
    },
    {
      title: "Build",
      text: "Unity and encouragement by motivating members to pray",
    },
  ];
  return (
    <>
      <section className="section-global bg-shade-1">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 text-center align-self-center">
              <img
                src={require("./assets/about.png")}
                className="img-fluid about-banner-image"
                alt="how we work"
              />
            </div>
            <div className="col-lg-6">
              <div className="section-tag mb-8">How we work</div>
              <h2 className="mb-16">
                Let's AMPLIFY Evangelism in the Digital Space
              </h2>
              <p className="txt-1 mb-16">
                Give members, friends, family, visitors and your community the
                ability to read the bible and submit prayers directly in the
                church app so that your church can encourage one another.
              </p>
              <div className="time-line">
                {timeline.map((e, i) => (
                  <div className="content" key={i}>
                    <div className="index">{i + 1}</div>
                    <h3 className="mb-8 mb-0">{e.title}</h3>
                    <p className="txt-2 mb-0">{e.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const Culture = () => {
  const culture_data = [
    {
      imgUrl: "./assets/culture_1.png",
      title: "We are focused, niche, and driven.",
      text: "Our life mission is to further and expand God's kingdom in the digital space. We solely make products that resonate with our faith and values.",
    },
    {
      imgUrl: "./assets/culture_2.png",
      title: "We've built software tailored made for churches",
      text: "By focusing on only churches and Christians, we can meticulously design products to address their specific challenges and achieving their goals.",
    },
    {
      imgUrl: "./assets/culture_3.png",
      title: "We aim to reach Christians globally",
      text: "Our goal is the spread God's kingdom as far and wide as we can. We believe our product can assist in helping God's word penetrate the digital space globally",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row mb-40">
            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2 text-center">
              <div className="section-tag mb-8">Culture</div>
              <h2>A technology-first approach to tithes and community</h2>
            </div>
          </div>
          <div className="row">
            {culture_data.map((e, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 text-center" key={i}>
                <img
                  src={require(`${e.imgUrl}`)}
                  className="img-fluid"
                  alt={e.title}
                />
                <h3>{e.title}</h3>
                <p className="txt-2">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Counters = () => {
  const counter_data = [
    {
      count: "1 million",
      text: "People Impacted",
    },
    {
      count: "100",
      text: "Churches Achieved",
    },
    {
      count: "$10,000,000",
      text: "Donated",
    },
    {
      count: "10",
      text: "Countries Reached",
    },
  ];
  const contact_data = {
    title: "One in a ",
    title_highlight: "million.",
    text: "Lighthouse's 5 year goal is to help 1 million people be touched by God's grace. Join us and you will receive a permanent number, making you officially one in a million.",
    link: "/contact",
  };
  return (
    <>
      <section className="counter-section dark">
        <div className="container">
          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 py-5 text-center col-cont">
            <h2>
              {contact_data.title}{" "}
              <span className="txt-highlight">
                {contact_data.title_highlight}
              </span>
            </h2>
            <p className="txt-1 mb-16">{contact_data.text}</p>
          </div>
          <div className="row">
            {counter_data.map((e, i) => (
              <div className="col-lg-3 col-md-4 col-6 gy-4 text-center" key={i}>
                <h2 className="display-1">{e.count}</h2>
                <p className="txt-1">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Pricing = () => {

  const pricing_data = [

      {
          // title: 'Pro',
          // text: 'For big teams',
          price: '$19',
          period: 'month',
          included: ['1 click Online Donation','Church Management System','Christian Social Network',"Bible School System"],
          featured: true
      },

  ]
  
  return(
      <>
          <section className='section-global'>
              <div className="container container-2">
                  <div className="row mb-40">
                      <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
                          <div className="section-tag mb-8">Pricing</div>
                          <h2>One plan is all you need</h2>
                      </div>
                  </div>
                  <div className="row pricing">
                      {pricing_data.map((e,i)=> 
                          <div className="col-lg-4 col-md-6 gy-4" key={i}>
                              <PricingCard data={e}/>
                          </div>
                      )}
                  </div>
              </div>
          </section>
      </>
  )
}

const Donate = () => {
  return (
    <>
      <section className="section-global bg-shade-1">
        <div className="container">
          <div className="row pt-5 gy-4">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Solutions</div>
              <h2 className="mb-16">
                85% of profits will be donated to charity
              </h2>
              <p className="txt-1">
                Our mission is to allocate 85% of our profits to support
                Christian-focused NGOs and charities, dedicated to advancing
                God's kingdom through software developement
              </p>
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div className="video-thumb" style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about4.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Home;
