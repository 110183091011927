import { Link } from 'react-router-dom'
import Logo from '../../logo.svg'
import './style.scss'

const Footer = () => {

    const links = [
        {
            title: 'Links',
            nav_links: [
                { name: 'Home', linkUrl: '/' },
                { name: 'Services', linkUrl: '/services' },
                { name: 'Contact', linkUrl: '/contact' },
                { name: 'Blog', linkUrl: '/blog' },
            ]
        },
        {
            title: 'Company',
            nav_links: [
                { name: 'About Us', linkUrl: '/about' },
                { name: 'Projects', linkUrl: '/projects' },
                { name: 'Blog', linkUrl: '/blog' },
                { name: 'Contact', linkUrl: '/contact' }
            ]
        },
        {
            title: 'Legal',
            nav_links: [
                { name: 'Privacy', linkUrl: '/' },
                { name: 'Terms of use', linkUrl: '/' }
            ]
        }
    ]

    return (
        <>
            <footer className="dark">
                <div className="container">
                    <div className="row gy-4 footer-section">
                        <div className="col-lg-3">
                            <img src={Logo} alt="Stact" height="32" />
                        </div>
                        <div className="col-lg-5">
                            <div className="link-section">
                                {links.map((e, i) =>
                                    <div className='me-5 me-lg-0 mt-4 mt-lg-0' key={i}>
                                        <div className="mb-8 txt-2 color-1 fw-500">{e.title}</div>
                                        <div className="links">
                                            {e.nav_links.map((el, j) => <Link key={j} to={el.linkUrl} className='link'>{el.name}</Link>)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className='ms-lg-auto'>
                                <div className='txt-3'>Email:&nbsp;<a href='mailto:hello@light-hx.com' className='contact-link'>hello@light-hx.com</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='txt-3 text-center footer-bottom'>Copyright © 2024 Lighthouse Software</div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer